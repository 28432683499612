import { useCallback, useState } from 'react';
import { AclGroupRole } from '@remento/types/acl';

import { RMConfirmationModal } from '@/components/RMConfirmationModal/RMConfirmationModal';
import { removeToast, toast, updateToast } from '@/components/RMToast/RMToast';
import { useProjectMembers } from '@/modules/sharing/hooks/useProjectMembers';
import { Member } from '@/modules/sharing/types';
import { useServices } from '@/Services';
import { captureException } from '@/utils/captureException';

import { ProjectShareMemberList } from '../components/ProjectShareMemberList';

export interface ProjectShareMemberListItemContainerProps {
  member: Member;
}

const getRoleLabel = (type: 'host' | 'collaborator' | 'storyteller' | 'invited'): string => {
  switch (type) {
    case 'host':
      return 'Owner';
    case 'collaborator':
    case 'invited':
      return 'Collaborator';
    case 'storyteller':
      return 'Storyteller';
    default:
      return 'Unknown';
  }
};

function ProjectShareMemberListItemContainer({ member }: ProjectShareMemberListItemContainerProps) {
  const { aclCacheService, collaborationAnalyticsService } = useServices();

  const [removeConfirmationOpen, setRemoveConfirmationOpen] = useState(false);

  const handleConfirmRemove = useCallback(async () => {
    const toastId = toast('Removing collaborator...', 'root-toast', 'default', {
      autoClose: false,
      draggable: false,
    });
    try {
      if (member.type === 'invited') {
        await aclCacheService.deleteGroupInvitee(member.aclGroupId, member.aclGroupInviteeId);
      } else {
        await aclCacheService.deleteGroupMember(member.aclGroupId, member.aclGroupMemberId);
      }
      updateToast(toastId, 'root-toast', {
        render: 'Collaborator removed successfully!',
        autoClose: 3000,
        draggable: true,
      });
    } catch (error) {
      removeToast(toastId);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [aclCacheService, member]);

  const handleRemove = useCallback(async () => {
    collaborationAnalyticsService.onInvitePermissionsChanged('remove');

    if (member.type !== 'invited') {
      setRemoveConfirmationOpen(true);
      return;
    }

    handleConfirmRemove();
  }, [collaborationAnalyticsService, handleConfirmRemove, member.type]);

  const handleCancelRemove = useCallback(() => {
    setRemoveConfirmationOpen(false);
  }, []);

  const handleResendInvite = useCallback(async () => {
    collaborationAnalyticsService.onInvitePermissionsChanged('resend-invite');

    const toastId = toast('Resending invite...', 'root-toast', 'default', { autoClose: false, draggable: false });
    try {
      await aclCacheService.sendEmailInvite(member.aclGroupId, [member.email]);
      updateToast(toastId, 'root-toast', {
        render: 'Invite resent successfully!',
        autoClose: 3000,
        draggable: true,
      });
    } catch (error) {
      removeToast(toastId);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [aclCacheService, collaborationAnalyticsService, member.aclGroupId, member.email]);

  const label = getRoleLabel(member.type);
  if (member.type === 'invited') {
    return (
      <ProjectShareMemberList.Item
        initials={''}
        name={''}
        photoUrl={null}
        email={member.email}
        readOnly={member.aclRole === AclGroupRole.ADMIN || member.aclRole === AclGroupRole.OWNER}
        onResendInvite={handleResendInvite}
        onRemove={handleRemove}
        pending={true}
        displayLabel={label}
      />
    );
  }

  return (
    <>
      <ProjectShareMemberList.Item
        initials={member.initials ?? ''}
        name={member.name?.full ?? ''}
        email={member.email}
        photoUrl={member.avatarUrl}
        avatarColor={member.avatarColor}
        readOnly={member.aclRole === AclGroupRole.ADMIN || member.aclRole === AclGroupRole.OWNER}
        onRemove={handleRemove}
        pending={false}
        displayLabel={label}
      />
      <RMConfirmationModal
        open={removeConfirmationOpen}
        type="danger"
        title="Remove this collaborator?"
        message="This action cannot be undone."
        confirmLabel="Remove"
        onConfirm={handleConfirmRemove}
        onCancel={handleCancelRemove}
        onClose={handleCancelRemove}
      />
    </>
  );
}

export interface ProjectShareMemberListContainerProps {
  projectId: string;
}

export function ProjectShareMemberListContainer({ projectId }: ProjectShareMemberListContainerProps) {
  const members = useProjectMembers(projectId);

  return (
    <ProjectShareMemberList.Root>
      {members?.map((member) => (
        <ProjectShareMemberListItemContainer key={member.email} member={member} />
      ))}
    </ProjectShareMemberList.Root>
  );
}
