import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

export const ButtonGroup = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-lg);
  > button {
    width: ${(props) => (props.isMobile ? '100%' : '100px')};
  }

  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};

  > button {
    margin-bottom: ${(props) => (props.isMobile ? '10px' : '0')};
  }
`;
