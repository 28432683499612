import { useCallback } from 'react';

import { useServices } from '@/Services.js';
import { usePersonQuery } from '@/services/api/person/index.js';

import { ProjectShareDialog } from '../components/ProjectShareDialog/ProjectShareDialog.js';

import { ProjectInviteByEmailContainer } from './ProjectInviteByEmail.container.js';
import { ProjectInviteByLinkContainer } from './ProjectInviteByLink.container.js';
import { ProjectShareMemberListContainer } from './ProjectShareMemberList.container.js';

export interface ProjectShareDialogContainerProps {
  projectId: string;
  open: boolean;
  onClose: () => void;
}

const intercomHelpUrl =
  'https://intercom.help/remento/en/articles/8365949-how-do-i-invite-collaborators-to-add-prompts-for-the-recipient';

export function ProjectShareDialogContainer({ projectId, open, onClose }: ProjectShareDialogContainerProps) {
  const { collaborationAnalyticsService } = useServices();
  const handleOpenHelp = useCallback(() => {
    window.open(intercomHelpUrl, '_blank');
    collaborationAnalyticsService.onHelpPressed();
  }, [collaborationAnalyticsService]);

  return (
    <ProjectShareDialog
      open={open}
      onOpenHelp={handleOpenHelp}
      onClose={onClose}
      InviteByEmail={<ProjectInviteByEmailContainer projectId={projectId} />}
      UserList={<ProjectShareMemberListContainer projectId={projectId} />}
      InviteByLink={<ProjectInviteByLinkContainer projectId={projectId} onClose={onClose} />}
    />
  );
}
