import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EntityType } from '@remento/types/entity.js';
import { ConflictError, ConflictErrorType, NotFoundError } from '@remento/types/error';

import { toast } from '@/components/RMToast/RMToast.js';
import { setInputValue } from '@/modules/form/input.js';
import { useServices } from '@/Services.js';
import { useProjectQuery } from '@/services/api/project/';
import { captureException } from '@/utils/captureException.js';

import { getFormValue } from '../../form/form.js';
import { ProjectInviteByEmail } from '../components/ProjectInviteByEmail/ProjectInviteByEmail.js';
import { createPersonInviteByEmailForm } from '../form/person-invite-by-email.form.js';

export interface ProjectInviteByEmailContainerProps {
  projectId: string;
}

export function ProjectInviteByEmailContainer({ projectId }: ProjectInviteByEmailContainerProps) {
  const { aclCacheService, collaborationAnalyticsService } = useServices();
  const form = useMemo(() => createPersonInviteByEmailForm(), []);
  const [searchParams] = useSearchParams();

  const projectQuery = useProjectQuery(projectId);

  const handleSubmit = useCallback(async () => {
    const formValue = getFormValue(form);
    if (!formValue) {
      return;
    }
    if (!projectQuery.data) {
      return;
    }

    try {
      collaborationAnalyticsService.onInvitedViaEmail(1, 'invite-dialog');

      const projectAclGroup = await aclCacheService.getPrimaryAclGroup(projectQuery.data.acl, EntityType.PROJECT);
      if (projectAclGroup == null) {
        throw new NotFoundError('primary-acl-group-not-found');
      }
      await aclCacheService.sendEmailInvite(projectAclGroup.id, [formValue.email]);
      toast('Invite sent');
      setInputValue(form, 'email', '');
    } catch (error) {
      if (error instanceof ConflictError && error.data?.type === ConflictErrorType.EMAIL_ALREADY_PART_OF_ACL_GROUP) {
        toast('This email is already associated with a collaborator on the account.', 'root-toast', 'error');
        return;
      }
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [aclCacheService, collaborationAnalyticsService, form, projectQuery.data]);

  useEffect(() => {
    const inviteEmail = searchParams.get('invite-email');
    if (inviteEmail != null) {
      setInputValue(form, 'email', inviteEmail);
    }
  }, [form, searchParams]);

  return <ProjectInviteByEmail form={form} onSubmit={handleSubmit} />;
}
