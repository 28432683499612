import { useCallback } from 'react';
import { EntityType } from '@remento/types/entity';
import { NotFoundError } from '@remento/types/error';

import { toast } from '@/components/RMToast/RMToast';
import { useServices } from '@/Services';
import { useProjectQuery } from '@/services/api/project';
import { captureException } from '@/utils/captureException';
import { openShareSheet, showShareSheetToast } from '@/utils/share-sheet';

import { ProjectInviteByLink } from '../components/ProjectInviteByLink/ProjectInviteByLink';

interface ProjectInviteByLinkContainerProps {
  projectId: string;
  onClose: () => void;
}

export function ProjectInviteByLinkContainer({ projectId, onClose }: ProjectInviteByLinkContainerProps) {
  const { aclCacheService, aclService, collaborationAnalyticsService } = useServices();
  const projectQuery = useProjectQuery(projectId);

  const handleCopy = useCallback(async () => {
    if (!projectQuery.data) {
      return;
    }

    try {
      collaborationAnalyticsService.onInvitedViaLink('invite-dialog');

      const projectAclGroup = await aclCacheService.getPrimaryAclGroup(projectQuery.data.acl, EntityType.PROJECT);
      if (projectAclGroup == null) {
        throw new NotFoundError('primary-acl-group-not-found');
      }
      const urlPromise = aclService.generateInviteLink(projectAclGroup.id);
      showShareSheetToast(await openShareSheet({ url: urlPromise }));
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        return;
      }
      captureException(error, true);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
    }
  }, [projectQuery.data, collaborationAnalyticsService, aclCacheService, aclService]);

  return <ProjectInviteByLink onCopy={handleCopy} onDone={onClose} />;
}
