import { useCallback } from 'react';

import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { toast } from '@/components/RMToast/RMToast';
import { useServices } from '@/Services';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';
import { captureException } from '@/utils/captureException';
import { genitiveCase } from '@/utils/genitiveCase';

export interface ResumeProjectConfirmationModalContainerProps {
  projectId: string;
  open: boolean;
  onClose: () => void;
}

export function ResumeProjectConfirmationModalContainer({
  projectId,
  open,
  onClose,
}: ResumeProjectConfirmationModalContainerProps) {
  const { entityCacheManagerService, projectService } = useServices();

  const projectQuery = useProjectQuery(projectId);
  const personQuery = usePersonQuery(projectQuery.data?.subjectPersonIds?.[0]);
  const project = projectQuery.data;
  const person = personQuery.data;

  const handleResume = useCallback(async () => {
    if (!project) {
      return;
    }

    try {
      await entityCacheManagerService.mutate(projectService.createSetProjectActiveMutation(project));
      onClose();
    } catch (error) {
      captureException(error, true);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
    }
  }, [entityCacheManagerService, onClose, project, projectService]);

  if (!person) {
    return null;
  }

  return (
    <RMConfirmationModal
      open={open}
      title={`Resume ${genitiveCase(person?.name?.first)} prompts?`}
      message={`${person.name?.first} will receive weekly prompts again starting on Wednesday.`}
      confirmLabel="Resume prompts"
      onConfirm={handleResume}
      onCancel={onClose}
      onClose={onClose}
    />
  );
}
